export interface IStaffRow {
	id?: string
	userType: 'Standard' | 'Admin'
	accessToPortal: 'Yes' | 'No'
	accessToMobile: 'Yes' | 'No'
	staff_name: string
	type: StaffType
	mobile: string
	email: string
	position: string
	street: string
	street_2: string
	city: string
	post_code: string
	pin: string
	start_date: string
	dob: string
	driver_license: string
	license_type: string
	license_class2: string
	endorsement: string
	endorsement_complete_date: string
	endorsement_expiry: string
	photo_front: string
	photo_back: string
	license_assessed_by: string
	induction_date: string
	expiry_date: string
	photo: string
	hs_assessed_by: string
	passport_num: string
	passport_type: string
	passport_issue: string
	passport_expiry: string
	passport_photo: string
	site_safe_assessed_by: string
	first_aid_issue: string
	first_aid_expiry: string
	first_aid_photo: string
	firstaid_assessed_by: string
	cert_num: string
	cert_issue_date: string
	cert_expiry_date: string
	cert_photo: string
	scaff_cert_assessed_by: string
	sop_train: string
	height_training: string
	height_training_issue: string
	height_training_expiry: string
	height_training_assessed_by: string
	other_photo: string
	ird_num: string
	last_drug_test: string
	kiwisaver: string
	employment_contract: string
	status: StaffStatus
	confined_uploaded_by: string | null
	confined_file: string
	confined_date: string
	confined_expiry_date: string
	high_risk_works_file: string
	high_risk_works_date: string
	high_risk_works_expiry_date: string
	high_risk_works_uploaded_by: string | null
	high_risk_works_class: string
	working_at_heights_file: string
	working_at_heights_date: string
	working_at_heights_expiry_date: string
	working_at_heights_uploaded_by: string | null
	white_card_file: string
	white_card_date: string
	white_card_expiry_date: string
	white_card_uploaded_by: string | null
	irata_file: string
	irata_date: string
	irata_expiry_date: string
	irata_uploaded_by: string | null
	irata_class: string
	master_climber_file: string
	master_climber_date: string
	master_climber_expiry_date: string
	master_climber_uploaded_by: string | null
	master_climber_class: string
	staff_file: string
	staff_date: string
	staff_expiry_date: string
	staff_uploaded_by: string | null
	staff_class: string
	is_health_and_safety_officer: boolean | string
	police_check_file: string
	police_check_date: string
	police_check_expiry_date: string
	police_check_uploaded_by: number | null
	dcsi_children_file: string
	dcsi_children_date: string
	dcsi_children_expiry_date: string
	dcsi_children_uploaded_by: number | null
	dcsi_aged_care_file: string
	dcsi_aged_care_date: string
	dcsi_aged_care_expiry_date: string
	dcsi_aged_care_uploaded_by: number | null
	// branch: string | null
}

export enum StaffType {
	'Employee' = 'Employee',
	'Crew Leader' = 'Crew Leader',
	'Office' = 'Office',
	'Scaffolder' = 'Scaffolder',
	'Truck Driver' = 'Truck Driver',
	'Application' = 'Application',
	'Contractor' = 'Contractor',
	'Yard Person' = 'Yard Person',
	'N/A' = '',
}

export enum StaffStatus {
	Active = 'Active',
	Inactive = 'Inactive',
}

export const StaffTypeOptions = Object.values(StaffType)
	.filter((v) => typeof v === 'string')
	.map((v) => v) as string[]

export const StaffStatusOptions = Object.values(StaffStatus)
	.filter((v) => typeof v === 'string')
	.map((v) => v) as string[]
