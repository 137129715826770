import { useState } from 'react'
import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options, branchOptions } from 'models'
import { job_status_options, job_status_options_Edit } from 'models/jobs.model'
import moment from 'moment'
import { useEffect } from 'react'
import {
	AddressServices,
	ClientServices,
	ContactServices,
	JobsServices,
} from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'
import { VariationTaskForm } from './VariationForm'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { CreateClientsForm } from 'components/Clients'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

const jobTypeOptions = [
	{
		value: 'Residential',
		label: 'Residential',
	},
	{
		value: 'Confined Spaces',
		label: 'Confined Spaces',
	},
	{
		value: 'Civil',
		label: 'Civil',
	},
	{
		value: 'Commercial',
		label: 'Commercial',
	},
	{
		value: 'Marine',
		label: 'Marine',
	},
	{
		value: 'Industrial',
		label: 'Industrial',
	},
]

const brandingOptions = [
	{
		value: 'Grovescaff',
		label: 'Grovescaff',
	},
]

interface AddressDTO {
	client_id?: number
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string
	formatted_address: string
}

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

export interface IDayWorksFormValues {
	client_id: number
	job_type: string
	branding: string
	site: string
	start_date: string
	end_date: string
	job_status: string
	notes: string
	status: string
	PO_Number: string
	Requester: string
	type: string
	description: string
	total_hours: number
	percentage_erect: number
	percentage_dismantle: number
	percentage_complete: number
	LastEditDate: string
	hire_rate: number | null
	task_value: number | null
	sqm_rate: number | null
	hourly_rate: number | null
	branch: string
}

export const DayWorksForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const [jobType, setJobType] = useState('')
	const [clientForm, setClientForm] = useState(false)
	const [validationSchema, setValidationSchema] = useState({})
	const { data: clientsData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const { data: contactsData } = ContactServices.useContacts()
	const { createTask } = JobsServices.useCreateTask()
	// const { createTask } = JobsServices.
	// const { data: addressData } = AddressServices.useAddressesByClientId(formik.values.client_id);
	const { data: addressData, isLoading: addressLoading } =
		AddressServices.useAddresses()

	const { createJob } = JobsServices.useCreateJob()
	const { update } = JobsServices.useUpdateJob()
	const { data: jobData, isLoading: jobLoading } = JobsServices.useJobById(
		job_id || undefined
	)
	const userState = useSelector((store: AppStore) => store.user)

	const dataLoading = clientsLoading || (jobLoading !== undefined && jobLoading) || addressLoading


	const getContact = (id: number) => {
		const requester = contactsData.find(
			(contact: { id: number }) => contact.id === id
		)
		return requester.name
	}

	const initialValues: IDayWorksFormValues = {
		client_id: jobData?.client_id || '',
		job_type: jobData?.job_type || '',
		branding: 'Grovescaff',
		site: jobData?.site || '',
		start_date: jobData?.start_date
			? moment(jobData.start_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		end_date: jobData?.end_date
			? moment(jobData.end_date).format('DD/MM/YYYY').toString()
			: '',
		job_status: jobData?.job_status || 'Pending Handover',
		notes: jobData?.notes || '',
		status: jobData?.status || 'Active',
		description: '',
		percentage_erect: 0,
		percentage_dismantle: 0,
		percentage_complete: 0,
		total_hours: 0,
		LastEditDate: '',
		PO_Number: '',
		Requester: '',
		type: '',
		hire_rate: 0,
		task_value: 0,
		sqm_rate: 2.25,
		hourly_rate: 70.5,
		branch: 'Nelson',
	}

	const formik = useFormik<IDayWorksFormValues>({
		initialValues,
		validationSchema: Yup.object(validationSchema),
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const JobData = {
					client_id: values.client_id,
					job_type: values.job_type,
					branding: values.branding,
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					descriptionOfQuote: values.description,
					client_contact: values.Requester,
					branch: values.branch,
					notes: values.notes,
				}
				const jobResponse = await createJob(JobData)
				const {
					PO_Number,
					Requester,
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					task_value,
					hire_rate,
					sqm_rate,
					hourly_rate,
					// type,
				} = values

				const data = {
					PO_Number,
					Requester: getContact(Number(Requester)),
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					LastEditDate: moment(values.LastEditDate, 'DD/MM/YYYY').toDate(),
					task_value,
					hire_rate,
					task_type: 'Task',
					type: 'Day Works',
					zone_label: 'Section 1',
					zone: 1,
					created_by: userState.name || '',
					sqm_rate,
					hourly_rate,
					// type,
				}

				await createTask(Number(jobResponse.data.job_id), data)
			}
			if (formType === 'update' && job_id) {
				const JobData = {
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					notes: values.notes,
					status: values.status,
					branch: values.branch,
				}
				await update(job_id, JobData)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		setValidationSchema({
			client_id: Yup.string().required('The client is required'),
			job_type: Yup.string().required('The job type is required'),
			Requester: Yup.string().required('Requester is required'),
			// type: Yup.string().required('Type is required'),
			end_date: Yup.string().optional(), // was string().required('Finish Date is required')
			description: Yup.string().required('Description is required'),
			percentage_erect: Yup.number().typeError(
				'Percentage Erect must be number'
			),
			percentage_dismantle: Yup.number().typeError(
				'Percentage Dismantle must be number'
			),
			hire_rate: Yup.number()
				.typeError('Hire Rate must be number')
				.required('Weekly Hire Rate is required'),
			total_hours: Yup.number()
				.typeError('Total Hours must be number')
				.required('Total Hours is required'),
			task_value: Yup.number()
				.typeError('Task Value must be number')
				.required('Task Value is required'),
		})

		formik.setFieldValue('job_status', 'In Progress')
	}, [formik.values.job_type])

	useEffect(() => {
		if (addressData && addressData.length > 0) {
			const address: AddressDTO | undefined = addressData.find(
				(address: any) => address.client_id === formik.values.client_id
			)
			if (address !== null && address !== undefined) {
				formik.setFieldValue('site', address.formatted_address)
			}
		}
	}, [formik.values.client_id])

	if ((job_id && clientsLoading) || jobLoading || addressLoading) {
		return <Spinner />
	}

	// console.log(clientsData)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting || dataLoading}
				formType={formType}>
				{formType === 'create' && (
					<>
						<div className="flex items-center px-3 pb-4 pt-2">
							<div className="flex flex-col w-full">
								<Dropdown
									label="Client"
									id="client_id"
									options={OptionsForDropdown(clientsData?.filter((client: any) => client.status === 'Active'), 'id', 'client_name')}
									value={formik.values.client_id}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.client_id}
								/>
								<div className="flex items-center pl-2">
									<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
									<button
										type="button"
										className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
										onClick={() => setClientForm(true)}>
										Add New Client
									</button>
								</div>
							</div>
							<div className="w-full pb-6">
								<Dropdown
									label="Job Type"
									id="job_type"
									options={jobTypeOptions}
									value={formik.values.job_type}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.job_type}
								/>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex items-center px-3">
									<div className="w-1/2 mt-6">
									<Dropdown
										label="Branch"
										id="branch"
										options={branchOptions}
										value={formik.values.branch}
										onChange={formik.setFieldValue}
										onBlur={formik.handleBlur}
									/>
								</div>

								<div className="w-full px-2 py-2 h-full">
									<label className="block mb-1 text-sm font-medium text-gray-700">
										Address Search
									</label>
									<Autocomplete
										apiKey={googleApiKey}
										placeholder=""
										style={{ width: '100%' }}
										className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-[40px] rounded-md shadow-sm pl-3 "
										onPlaceSelected={(place) => {
											formik.setFieldValue('site', place.formatted_address)
										}}
										options={{
											types: ['address'],
											componentRestrictions: { country: 'nz' },
										}}
									/>
								</div>
							</div>
							<div className="flex items-center px-6 pb-6">
								<div>
									<span className="font-semibold">
										Current Selected Address:
									</span>
									<br /> {formik.values.site}
								</div>
							</div>
						</div>
					</>
				)}

				<>
					<div className="flex items-center px-3">
						<DateSelect
							title="Start Date"
							id="start_date"
							value={formik.values.start_date}
							onChange={formik.setFieldValue}
						/>
						{/* <DateSelect
							title="Finish Date"
							id="end_date"
							value={formik.values.end_date}
							onChange={formik.setFieldValue}
							error={formik.errors.end_date}
						/> */}
					</div>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								label="Job Status"
								id="job_status"
								options={
									formType === 'create'
										? job_status_options
										: job_status_options_Edit
								}
								value={formik.values.job_status}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								disabled={formType === 'create'}
							/>
						</div>
					</div>
				</>

				<VariationTaskForm
					formik={formik}
					formType="create"
					clientsData={clientsData}
					contactsData={contactsData}
					client_id={formik.values.client_id}
				/>

				<CreateClientsForm
					open={clientForm}
					setOpen={setClientForm}
					heading="Create New Client"
					formType="create"
				/>
			</SideModal>
		</>
	)
}
